import { useShow,useMany, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import { Show, Typography, Card, useTable } from "@pankod/refine-antd";

import { IFullShareholder } from "interfaces";

const { Title, Text } = Typography;

export const ShareholderShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IFullShareholder>({resource: "shareholders"});
    const { data, isLoading } = queryResult;
    const record = data?.data;

    // const { data: categoryData, isLoading: categoryIsLoading } =
    // useMany<IFullShareholder>({
    //          resource: "shareholders",
    //          personId: record?.PersonId,
    //          queryOptions: {
    //              enabled: !!record,
    //          },
    //      });
    const formatNumber = (num?:number) => {
        return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       }
    return (
        <Show isLoading={isLoading} title={record?.FOUNDER_NAME}>
            <Card title="Thông tin cổ đông">
            <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>
                <tbody className="ant-table-tbody">
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell">ID Number:</td>
                <td className="ant-table-cell">{record?.PERS_DOC_NO}</td>
            </tr>      
            <tr>
                <td>Name:</td>
                <td>{record?.FOUNDER_NAME}</td>
            </tr>    
            <tr>
                    <td>Date of birth/Issue:</td>
                    <td>{record?.DATE_OF_BIRTH}</td>
                </tr> 
                <tr>
                    <td>ID No.:</td>
                    <td>{record?.PERS_DOC_NO}</td>
                </tr>
                <tr>
                    <td>Issue Date:</td>
                    <td>{record?.PERS_DOC_ISSUE_DATE}</td>
                </tr>
                <tr>
                    <td>Address:</td>
                    <td>{record?.ADDRESS_VN}</td>
                </tr>
                <tr>
                    <td>City:</td>
                    <td>{record?.CITY}</td>
                </tr>
                <tr>
                    <td>Natinality:</td>
                    <td>{record?.NATIONALITY}</td>
                </tr>                
                <tr>
                    <td>Phone:</td>
                    <td>{record?.PHONE}</td>
                </tr>
                <tr>
                    <td><b>Shared at:</b></td>
                    <td><b>{record?.business.BusinessName} - {record?.business.TaxCode}</b></td>
                </tr>
                <tr>
                    <td>Ratio:</td>
                    <td>{record?.RATIO_IN_CHARTER_CPT}%</td>
                </tr>
                <tr>
                    <td>Total shares:</td>
                    <td>{formatNumber(record?.TOTAL_SHARES_VALUE)}</td>
                </tr>                
                </tbody>
            </table>
            </Card>
        </Show>
    );
};
