import { useShow,useMany, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import { Show, Typography, Card, useTable } from "@pankod/refine-antd";


import { IFullInvoice } from "interfaces/invoice";

const { Title, Text } = Typography;

export const InvoiceShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IFullInvoice>({resource: "invoices"});
    const { data, isLoading } = queryResult;
    const record = data?.data;

    // const { data: categoryData, isLoading: categoryIsLoading } =
    // useMany<IFullShareholder>({
    //          resource: "shareholders",
    //          personId: record?.PersonId,
    //          queryOptions: {
    //              enabled: !!record,
    //          },
    //      });
    const formatNumber = (num?:number) => {
        return num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       }
       const formatTime = (time:Date, prefix = "") => { 
        return time? prefix + new Date(time).toLocaleDateString('vi-VN') : "";
    }
    return (
        <Show isLoading={isLoading} title={record?.TenNguoiNopThue}>
            <Card title="Thông tin cưỡng chế hóa đơn">
            <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>
                <tbody className="ant-table-tbody">
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell" style={{width: "30%"}}><b>Người nộp thuế:</b></td>
                <td className="ant-table-cell" style={{width: "70%"}}><b>{record?.TenNguoiNopThue}</b></td>
            </tr>
            <tr>
                <td>Mã số thuế:</td>
                <td>{record?.MaSoThue}</td>
            </tr> 
            <tr>
                <td>Ngày quyết định:</td>
                <td>{record?.NgayQuyetDinh}</td>
            </tr>
            <tr>
                <td>Tỉnh / thành phố:</td>
                <td>{record?.province?.ProvinceName}</td>
            </tr>            
            <tr>
                <td>Quyết định cưỡng chế:</td>
                <td> <ul>
                                    {record?.QuyetDinhCuongCheData?.map(                                  
                                  (item)=><li><a href={"/download/"+item.FileName}>{item.Name}</a></li>
                                        )}                                          
                                </ul></td>
            </tr>             
            <tr>
                <td>Thông báo hóa đơn tiếp tục có giá trị sử dụng:</td>
                <td><ul>
                                    
                                          {record?.ThongBaoHoaDonData?.map(                                  
                                  (item)=><li><a href={"/download/"+item.FileName}>{item.Name}</a></li>
                                        )}
                                </ul></td>
            </tr>             
            
                </tbody>
            </table>
            </Card>
        </Show>
    );
};
