import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout, LoginPage,
  ErrorComponent
} from "@pankod/refine-antd";
import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";

import "@pankod/refine-antd/dist/styles.min.css";

import { PostList, PostCreate, PostEdit, PostShow } from "pages/posts";
import { BusinessList, BusinessShow } from "pages/businesses";
import { DkkdList, DkkdFSShow } from "pages/dkkdfs";
import { Dscd2024, CDShow } from "pages/codong";
import { InvoiceList, InvoiceShow } from "pages/invoices";
import { Shareholders, ShareholderShow} from "pages/shareholders";
import { Litigations, LitigationShow} from "pages/litigations";
import { PersonList, PersonShow} from "pages/persons";
import { FdiList, FdiShow} from "pages/fdis";
import { ListedList, ListedShow} from "pages/listed";
import { Login } from "pages/accounts/login";
import authProvider from "./authProvider";
//const API_URL = "http://localhost:5057/api";
const API_URL = "http://27.72.104.59:5057/api";
//const API_URL = "http://192.168.1.3:5057/api";
const App: React.FC = () => {
  // const authProvider: AuthProvider = {
  //   login: (params: any) => {
  //     if (params.username === "info@vietnamcredit.com.vn") {
  //       localStorage.setItem("username", params.username);
  //       return Promise.resolve();
  //     }

  //     return Promise.reject();
  //   },
  //   logout: () => {
  //     localStorage.removeItem("username");
  //     return Promise.resolve();
  //   },
  //   checkError: () => Promise.resolve(),
  //   checkAuth: () =>
  //     localStorage.getItem("username") ? Promise.resolve() : Promise.reject(),
  //   getPermissions: () => Promise.resolve(["vietnamcredit@123"]),
  //   getUserIdentity: () =>
  //     Promise.resolve({
  //       id: 1,
  //       name: "Le Anh Tuan",
  //       avatar:
  //         "https://unsplash.com/photos/IWLOvomUmWU/download?force=true&w=640"
  //     })
  // };
 
  return (
    <Refine
    authProvider={authProvider}
      dataProvider={dataProvider(API_URL)}
      routerProvider={routerProvider}
      Footer={() => (
        <div
          style={{
            backgroundColor: "white",
            height: "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          Vietnam Credit @2022
        </div>
      )}
      resources={[
        {
          name: "dkkds",
          options: {label:"Business 2024" },
          list: BusinessList,
          // create: PostCreate,
          // edit: PostEdit,
          show: BusinessShow
        },
        {
          name: "dscds",
          options: {label:"Shareholder 2024" },
          list: Dscd2024,
          // create: PostCreate,
          // edit: PostEdit,
          show: CDShow
        },
        {
          name: "dkkdfs",
          options: {label:"FS 2023" },
          list: DkkdList,
          // create: PostCreate,
          // edit: PostEdit,
          show: DkkdFSShow
        },
        {
          name: "businesses",
          list: PostList,
          // create: PostCreate,
          // edit: PostEdit,
          show: PostShow
        },
         {
           name: "shareholders",
           list: Shareholders,           
           //create: PostCreate,
           //edit: PostEdit,
           show: ShareholderShow
         },
         {
          name: "persons",
          list: PersonList,          
          show: PersonShow          
        },
         {
          name: "litigations",          
          list: Litigations,
          //create: PostCreate,
          //edit: PostEdit,
          show: LitigationShow
        },
        {
          name: "fdis",         
          options: {label:"FDI" },           
          list: FdiList,          
          show: FdiShow
        },
        {
          name: "listed",         
          options: {label:"DS Niêm Yết" },           
          list: ListedList,          
          show: ListedShow
        },
        {
          name: "invoices",         
          options: {label:"Cưỡng chế hóa đơn" },           
          list: InvoiceList,          
          show: InvoiceShow
        }       
      ]}
      notificationProvider={notificationProvider}
      LoginPage={Login}
      Layout={Layout}
      catchAll={<ErrorComponent />}
      syncWithLocation
      Title={({ collapsed }) => (
        <div>
            {collapsed? <img src="/vietnamcredit.png" alt="Logo" /> : <img src="/vietnamcredit.png" alt="Logo" width={200} />}            
        </div>
    )}
    />
  );
};

export default App;
