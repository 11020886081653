import { useShow, IResourceComponentsProps, useOne, useExport } from "@pankod/refine-core";

import { Show, Typography, MarkdownField, Row, Col, Card, ExportButton } from "@pankod/refine-antd";

import { IDkkdFS, IFs2024, IProvince } from "interfaces";


const { Title, Text } = Typography;
import "./styles.css";

export const DkkdFSShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IDkkdFS>({resource: "dkkdfs"});
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const fs2024s = data?.data.fs2024s;
    
    // const { triggerExport, isLoading : exportLoading } = useExport<IFs2024>({
    //     resourceName: "dkkdfs",
    //     mapData: (item) => { console.log(item)
    //         return {
    //             ...item,
    //             // category is an object, we need to stringify it
    //             //category: JSON.stringify(item.category),
    //         };
    //     },
    // });

    const { triggerExport, isLoading: exportLoading } = useExport<IFs2024>({ 
        resourceName: "fsdata/"+record?.id,       
        mapData: (item) => {
          return {
            //id: item.ID,
            THU_TU: item.THU_TU,    
            //MA_DTNT: item.MA_DTNT,
            MA_CTBC : item.MA_CTBC,
            TEN_CTBC: item.danhmucct.TEN_CTBC,
            SO_DK: currencyFormat(item.SO_DK),
            SO_CK: currencyFormat(item.SO_CK),
          };
        },
        onError: (error) => {
            console.log(error);
          },
      });

    // const { data: categoryData, isLoading: categoryIsLoading } =
    //     useOne<IProvince>({
    //         resource: "provinces",
    //         id: record?.ProvinceId || "",
    //         queryOptions: {
    //             enabled: !!record,
    //         },
    //     });
        function currencyFormat(num?:Number) {
            if(num == null)
                return '';
            return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
         }
         const formatNumber = (num?:number) => {
            return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
           } 
    return (
        <Show isLoading={isLoading} title={record?.NAME}>
            
             <Card title="Thông tin pháp lý">
            <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>
                <tbody className="ant-table-tbody">
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell" style={{width: "20%"}}>Tax Code:</td>
                <td className="ant-table-cell" style={{width: "80%"}}>{record?.ENTERPRISE_GDT_CODE}</td>
            </tr>      
            <tr>
                <td><b>Business Name:</b></td>
                <td><b>{record?.NAME}</b></td>
            </tr>     
                <tr>
                    <td>Business Type:</td>
                    <td>{record?.ENTERPRISE_TYPE_ID}</td>
                </tr>
                <tr>
                    <td>FOUNDING DATE:</td>
                    <td>{record?.FOUNDING_DATE}</td>
                </tr>
                <tr>
                    <td>REGISTRATION DATE:</td>
                    <td>{record?.REGISTRATION_DATE}</td>
                </tr>
                <tr>
                    <td>ADDRESS:</td>
                    <td>{record?.ADDRESS}</td>
                </tr>
                <tr>
                    <td>ACTIVITY:</td>
                    <td>{record?.BUSINESS_NAME}</td>
                </tr>
                <tr>
                    <td>Fiscal Year:</td>
                    <td>{record?.FISCAL_YEAR}</td>
                </tr>
                <tr>
                    <td>STATEMENT TYPE ID:</td>
                    <td>{record?.STATEMENT_TYPE_ID}</td>
                </tr>
                <tr>
                    <td>FINANCIAL DATE:</td>
                    <td>{record?.FINANCIAL_DATE}</td>
                </tr>
                <tr>
                    <td>CURRENCY UNIT:</td>
                    <td>{record?.CURRENCY_UNIT}</td>
                </tr>
                <tr>
                    <td>TAX METHOD:</td>
                    <td>{record?.TAX_METHOD}</td>
                </tr>
                <tr>
                    <td>SUBMISSION DATE:</td>
                    <td>{record?.SUBMISSION_DATE} </td>
                </tr>               
                <tr>
                    <td>FISCAL START DATE:</td>
                    <td>{record?.FISCAL_START_DATE}</td>
                </tr>
                <tr>
                    <td>FISCAL END DATE:</td>
                    <td>{record?.FISCAL_END_DATE}</td>
                </tr>                
                </tbody>
            </table>
            </Card>            
            <Card title="FS 2023">
            {/* <button onClick={triggerExport} disabled={isLoading}>
            
                  {exportLoading ? "Exporting..." : "Export Products"}
              </button> */}
              
                <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>  
                <tbody className="ant-table-tbody">
                <tr>                    
                    {/* <td>Loai BC</td> */}
                    <td></td>
                    <td></td>
                    <td></td>                    
                    <td></td>
                    <td><ExportButton onClick={triggerExport} loading={exportLoading} /></td>
                    </tr> 
                <tr>                    
                    {/* <td>Loai BC</td> */}
                    <td>Thứ Tự</td>
                    <td>Mã Chỉ Tiêu</td>
                    <td>Tên Chỉ Tiêu</td>                    
                    <td>Số Đầu Kỳ</td>
                    <td>Số Cuối Kỳ</td>
                    </tr>   
                    {fs2024s &&
              fs2024s.map((item, index) => (
                    <tr>                    
                    {/* <td>{item.ID_LOAI_BC}</td> */}
                    <td>{item.THU_TU}</td>
                    <td>{item.MA_CTBC}</td>
                    <td>{item.danhmucct?.TEN_CTBC}</td>                    
                    <td>{currencyFormat(item.SO_DK)}</td>
                    <td>{currencyFormat(item.SO_CK)}</td>
                    </tr>   
                    ))}              
                </tbody>
                </table>
              
            </Card>
            
        </Show>
    );
};
