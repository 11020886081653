import { useShow,useMany, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import { Show, Typography, Card, useTable, MarkdownField } from "@pankod/refine-antd";


import { IFullListed } from "interfaces/listed";

const { Title, Text } = Typography;

export const ListedShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IFullListed>({resource: "listed"});
    const { data, isLoading } = queryResult;
    const record = data?.data;

    // const { data: categoryData, isLoading: categoryIsLoading } =
    // useMany<IFullShareholder>({
    //          resource: "shareholders",
    //          personId: record?.PersonId,
    //          queryOptions: {
    //              enabled: !!record,
    //          },
    //      });
    const formatNumber = (num?:number) => {
        return num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       }
       const formatTime = (time:Date, prefix = "") => { 
        return time? prefix + new Date(time).toLocaleDateString('vi-VN') : "";
    }
    return (
        <Show isLoading={isLoading} title={record?.TenDayDu}>
            <Card title="Thông tin doanh nghiệp">
            <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>
                <tbody className="ant-table-tbody">
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell" style={{width: "20%"}}><b>Tên đầy đủ:</b></td>
                <td className="ant-table-cell" style={{width: "80%"}}><b>{record?.TenDayDu}</b></td>
            </tr> 
            <tr>
                <td>Tên tiếng anh:</td>
                <td>{record?.TenTiengAnh}</td>
            </tr>     
            <tr>
                <td>Tên viết tắt:</td>
                <td>{record?.TenVietTat}</td>
            </tr>
            <tr>
                <td>Địa chỉ:</td>
                <td>{record?.DiaChi}</td>
            </tr>            
            <tr>
                <td>Người công bố:</td>
                <td>{record?.NguoiCongBo}</td>
            </tr> 
            <tr>
                <td>Điện thoại:</td>
                <td>{record?.DienThoai}</td>
            </tr>
            <tr>
                <td>Fax:</td>
                <td>{record?.Fax}</td>
            </tr> 
            <tr>
                <td>Email:</td>
                <td>{record?.Email}</td>
            </tr>
            <tr>
                <td>Website:</td>
                <td>{record?.Website}</td>
            </tr>
            <tr>
                <td><b>Mã chứng khoán:</b></td>
                <td><b>{record?.MaCK}</b></td>
            </tr>
            <tr>
                <td><b>Sàn giao dịch:</b></td>
                <td><b>{record?.SanGiaoDich}</b></td>
            </tr>
            <tr>
                <td>Nhóm ngành:</td>
                <td>{record?.NhomNganh}</td>
            </tr>
            <tr>
                <td>Ngành:</td>
                <td>{record?.Nganh}</td>
            </tr>
            <tr>
                <td>Ngày niêm yết:</td>
                <td>{record?.NgayNiemYet}</td>
            </tr>
            <tr>
                <td>Vốn điều lệ:</td>
                <td>{formatNumber(record?.VonDieuLe)}</td>
            </tr>
            <tr>
                <td>Số CP niêm yết:</td>
                <td>{formatNumber(record?.SoCPNiemYet)}</td>
            </tr>
            <tr>
                <td>Số CP đang LH:</td>
                <td>{formatNumber(record?.SoCPdangLH)}</td>
            </tr>
            <tr>
                <td>Trạng thái:</td>
                <td>{record?.TrangThai}</td>
            </tr>
            <tr>
                <td><b>Mã số thuế:</b></td>
                <td><b>{record?.MaSoThue}</b></td>
            </tr>
            <tr>
                <td>GPTL:</td>
                <td>{record?.GPTL}</td>
            </tr>
            <tr>
                <td>Ngày cấp GPTL:</td>
                <td>{record?.NgayCapGPTL}</td>
            </tr>
            <tr>
                <td>GPKD:</td>
                <td>{record?.GPKD}</td>
            </tr>
            <tr>
                <td>Ngày cấp GPKD:</td>
                <td>{record?.NgayCapGPKD}</td>
            </tr>
            <tr>
                <td>Ngành nghề chính:</td>
                <td>{record?.NganhNgheChinh}</td>
            </tr>
            <tr>
                <td>Văn phòng đại diện:</td>
                <td>{record?.VPDaiDien}</td>
            </tr>
            <tr>
                <td>Mốc lịch sử:</td>
                <td>
<div dangerouslySetInnerHTML={{__html: record?.MocLichSu!}} />
</td>
            </tr>
            <tr>
                <td>Sự kiện:</td>
                <td>                    
                    <div dangerouslySetInnerHTML={{__html: record?.SuKien!}} />
                </td>
            </tr>
            <tr>
                <td>Ghi chú:</td>
                <td>
                <div dangerouslySetInnerHTML={{__html: record?.GhiChu!}} />
                </td>
            </tr>            
                </tbody>
            </table>
            </Card>
        </Show>
    );
};
