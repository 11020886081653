import { useShow,useMany, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import { Show, Typography, Card, useTable } from "@pankod/refine-antd";


import { IFullFdi } from "interfaces/fdi";

const { Title, Text } = Typography;

export const FdiShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IFullFdi>({resource: "fdis"});
    const { data, isLoading } = queryResult;
    const record = data?.data;

    // const { data: categoryData, isLoading: categoryIsLoading } =
    // useMany<IFullShareholder>({
    //          resource: "shareholders",
    //          personId: record?.PersonId,
    //          queryOptions: {
    //              enabled: !!record,
    //          },
    //      });
    const formatNumber = (num?:number) => {
        return num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       }
       const formatTime = (time:Date, prefix = "") => { 
        return time? prefix + new Date(time).toLocaleDateString('vi-VN') : "";
    }
    return (
        <Show isLoading={isLoading} title={record?.TDUAN}>
            <Card title="Thông tin dự án">
            <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>
                <tbody className="ant-table-tbody">
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell" style={{width: "20%"}}><b>Tên dự án:</b></td>
                <td className="ant-table-cell" style={{width: "80%"}}><b>{record?.TDUAN}</b></td>
            </tr> 
            <tr>
                <td>Địa chỉ:</td>
                <td>{record?.TRSOCT}</td>
            </tr>     
            <tr>
                <td>Tỉnh/thành phố:</td>
                <td>{record?.DDTH}</td>
            </tr>
            <tr>
                <td>Quận/ Huyện:</td>
                <td>{record?.QUANHUYEN}</td>
            </tr>            
            <tr>
                <td>Số GCNĐT:</td>
                <td>{record?.SOGCNDT}</td>
            </tr> 
            <tr>
                <td>Ngày cấp GCNĐT:</td>
                <td>{formatTime(record?.NGAYGCNDT)}</td>
            </tr>
            <tr>
                <td>Đơn vị:</td>
                <td>{record?.DONVI}</td>
            </tr> 
            <tr>
                    <td>Nước đầu tư:</td>
                    <td>{record?.FDKKD}</td>
                </tr> 
            <tr>
                <td>Tổng vốn đầu tư:</td>
                <td>{formatNumber(record?.TVDT)}</td>
            </tr>
            <tr>
                <td>Tổng vốn đầu tư ban đầu:</td>
                <td>{formatNumber(record?.OLD_TVDT)}</td>
            </tr>
            <tr>
                <td>Vốn điều lệ:</td>
                <td>{formatNumber(record?.VONPD)}</td>
            </tr>
            <tr>
                <td>Vốn điều lệ ban đầu:</td>
                <td>{formatNumber(record?.OLD_VONPD)}</td>
            </tr>
            <tr>
                <td>Vốn điều lệ nước ngoài:</td>
                <td>{formatNumber(record?.FVONPD)}</td>
            </tr>
            <tr>
                <td>Vốn góp:</td>
                <td>{formatNumber(record?.VONGOP)}</td>
            </tr>
            <tr>
                <td>Vốn vay:</td>
                <td>{formatNumber(record?.VONVAY)}</td>
            </tr>
            <tr>
                <td>Vốn đầu tư tăng:</td>
                <td>{formatNumber(record?.DTTANG1)}</td>
            </tr>
            <tr>
                <td>Vốn điều lệ tăng:</td>
                <td>{formatNumber(record?.PDTANG1)}</td>
            </tr>
            <tr>
                <td>Ngày tăng:</td>
                <td>{record?.NGAYTANG1? formatTime(record?.NGAYTANG1) : '' }</td>
            </tr>
            <tr>
                <td>Công ty bên nước ngoài:</td>
                <td>{record?.FTCOTY}</td>
            </tr>
            <tr>
                <td>Tỷ lệ vốn nước ngoài:</td>
                <td>{record?.FRATE}</td>
            </tr>
            <tr>
                <td>Hình thức đầu tư:</td>
                <td>{record?.HTDT}</td>
            </tr>
            <tr>
                <td>Thời hạn:</td>
                <td>{record?.THHAN}</td>
            </tr>
            <tr>
                    <td>Chuyên ngành (ISIC1):</td>
                    <td>{record?.ISIC1}</td>
            </tr> 
                <tr>
                    <td>Ngành nghề:</td>
                    <td>{record?.NDSXKD}</td>
                </tr>
                <tr>
                    <td>Tiến độ góp vốn:</td>
                    <td>{record?.TIENDOGOPVON}</td>
                </tr>
                <tr>
                    <td>Tiến độ thực hiện:</td>
                    <td>{record?.TIENDOTHUCHIEN}</td>
                </tr>
                <tr>
                    <td>Diện tích đất:</td>
                    <td>{record?.DIENTICHDAT}</td>
                </tr>
                <tr>
                    <td>Diện tích nhà xưởng:</td>
                    <td>{record?.DIENTICHNHAXUONG}</td>
                </tr>
                <tr>
                    <td>Quy mô dự án:</td>
                    <td>{record?.QUYMODUAN}</td>
                </tr>
                </tbody>
            </table>
            </Card>
        </Show>
    );
};
