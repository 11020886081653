import { useShow,useMany, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import { Show, Typography, Card, useTable } from "@pankod/refine-antd";


import { ILitigation } from "interfaces/litigation";

const { Title, Text } = Typography;

export const LitigationShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<ILitigation>({resource: "litigations"});
    const { data, isLoading } = queryResult;
    const record = data?.data;

    // const { data: categoryData, isLoading: categoryIsLoading } =
    // useMany<IFullShareholder>({
    //          resource: "shareholders",
    //          personId: record?.PersonId,
    //          queryOptions: {
    //              enabled: !!record,
    //          },
    //      });
    const formatNumber = (num?:number) => {
        return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       }
    return (
        <Show isLoading={isLoading} title={record?.TenVuAn}>
            <Card title="Thông tin xét xử">
            <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>
                <tbody className="ant-table-tbody">
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell">Tên vụ an/việc kiện:</td>
                <td className="ant-table-cell">{record?.TenVuAn}</td>
            </tr>      
            <tr>
                <td>Cấp xét xử:</td>
                <td>{record?.CapXetXu}</td>
            </tr>    
            <tr>
                    <td>Tòa xét xử:</td>
                    <td>{record?.ToaXetXu}</td>
                </tr> 
                <tr>
                    <td>Ngày xét xử:</td>
                    <td>{record?.NgayXetXu}</td>
                </tr>                              
                </tbody>
            </table>
            </Card>
        </Show>
    );
};
