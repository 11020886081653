import {
    IResourceComponentsProps,
    useMany,
    CrudFilters,
    HttpError,
} from "@pankod/refine-core";

import {
    List,
    Table,
    TextField,
    
    UrlField,
    useTable,
    Space,
    EditButton,
    ShowButton,
    useSelect,
    Form,
    Input,
    Button,
    DatePicker,
    DateField,    
    Card,
    Icons,
    Select,
    Tag,
    FormProps,
    Row,
    Col,
} from "@pankod/refine-antd";

import { IInvoiceFilterVariables, IInvoice } from "interfaces/invoice";

const { RangePicker } = DatePicker;

export const InvoiceList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, searchFormProps } = useTable<
    IInvoice,        
        HttpError,
        IInvoiceFilterVariables        
    >({
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, key } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                // {
                //     field: "provinceId",
                //     operator: "eq",
                //     value: province,
                // },
                {
                    field: "key",
                    operator: "eq",
                    value: key,
                },
               
            );

            return filters;
        },
    });

    
    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List title="Quyết định cưỡng chế hóa đơn">
                    <Table {...tableProps} rowKey="Id">
                        {/* <Table.Column dataIndex="Id" title="ID" /> */}
                        <Table.Column dataIndex="MaSoThue" title="Mã số thuế" />
                        <Table.Column dataIndex="TenNguoiNopThue" title="Tên doanh nghiệp" />
                        <Table.Column dataIndex="NgayQuyetDinh" title="Ngày quyết định" />
                        <Table.Column<IInvoice> dataIndex={["QuyetDinhCuongCheData", "Name"]} title="Hồ sơ" 
                        render={(_, record) => {
                            // if (isLoading) {
                            //     return <TextField value="Loading..." />;
                            // }
    
                            return (
                                <ul>
                                    {record?.QuyetDinhCuongCheData?.map(                                  
                                  (item)=><li><a href={"/download/"+item.FileName}>{item.Name}</a></li>
                                        )}
                                          {record?.ThongBaoHoaDonData?.map(                                  
                                  (item)=><li><a href={"/download/"+item.FileName}>{item.Name}</a></li>
                                        )}
                                </ul>
                            );
                        }}
                        />
                        <Table.Column dataIndex={["province", "ProvinceName"]} title="Tỉnh / Thành" />                 
                         
                       
                        <Table.Column<IInvoice>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>                                    
                                    <ShowButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

//    const { selectProps: provinceSelectProps } = useSelect<IProvince>({
//         resource: "provinces",
//         optionLabel: "ProvinceName",
//         optionValue: "Id",
//    });

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label="Mã số thuế" name="q">
                <Input
                    placeholder="Taxcode, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Tên công ty" name="key">
                <Input
                    placeholder="name, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            {/* <Form.Item label="Status" name="status">
                <Select
                    allowClear
                    options={[
                        {
                            label: "Published",
                            value: "published",
                        },
                        {
                            label: "Draft",
                            value: "draft",
                        },
                        {
                            label: "Rejected",
                            value: "rejected",
                        },
                    ]}
                    placeholder="Post Status"
                />
            </Form.Item> */}
            {/* <Form.Item label="Province" name="province">
                <Select
                    {...provinceSelectProps}
                    allowClear
                    placeholder="Search Provinces"
                />
            </Form.Item> */}
            {/* <Form.Item label="Created At" name="createdAt">
                <RangePicker />
            </Form.Item> */}
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};
