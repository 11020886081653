import {
    IResourceComponentsProps,
    useMany,
    CrudFilters,
    HttpError,
} from "@pankod/refine-core";

import {
    List,
    Table,
    TextField,
    useTable,
    Space,
    EditButton,
    ShowButton,
    useSelect,
    Form,
    Input,
    Button,
    DatePicker,
    DateField,
    Card,
    Icons,
    Select,
    Tag,
    FormProps,
    Row,
    Col,
} from "@pankod/refine-antd";

import { IFdiFilterVariables, IFdi } from "interfaces/fdi";

const { RangePicker } = DatePicker;

export const FdiList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, searchFormProps } = useTable<
    IFdi,        
        HttpError,
        IFdiFilterVariables        
    >({
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, key, investor } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },              
                {
                    field: "key",
                    operator: "eq",
                    value: key,
                },
                {
                    field: "investor",
                    operator: "eq",
                    value: investor,
                },
               
            );

            return filters;
        },
    });

    
    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List>
                    <Table {...tableProps} rowKey="Id">
                        {/* <Table.Column dataIndex="Id" title="ID" /> */}
                        <Table.Column dataIndex="TDUAN" title="Tên dự án" />
                        <Table.Column dataIndex="SOGCNDT" title="Số giấy chứng nhận" />
                        <Table.Column dataIndex="TRSOCT" title="Địa chỉ" />
                        <Table.Column dataIndex="FDKKD" title="Nước đầu tư" />
                        <Table.Column dataIndex="FTCOTY" title="Nhà đầu tư" />
                        <Table.Column<IFdi>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>                                    
                                    <ShowButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

//    const { selectProps: provinceSelectProps } = useSelect<IProvince>({
//         resource: "provinces",
//         optionLabel: "ProvinceName",
//         optionValue: "Id",
//    });

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label="Từ khóa" name="q">
                <Input
                    placeholder="Taxcode, name, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Địa chỉ" name="key">
                <Input
                    placeholder="name, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Nhà đầu tư" name="investor">
                <Input
                    placeholder="name, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            {/* <Form.Item label="Status" name="status">
                <Select
                    allowClear
                    options={[
                        {
                            label: "Published",
                            value: "published",
                        },
                        {
                            label: "Draft",
                            value: "draft",
                        },
                        {
                            label: "Rejected",
                            value: "rejected",
                        },
                    ]}
                    placeholder="Post Status"
                />
            </Form.Item> */}
            {/* <Form.Item label="Province" name="province">
                <Select
                    {...provinceSelectProps}
                    allowClear
                    placeholder="Search Provinces"
                />
            </Form.Item> */}
            {/* <Form.Item label="Created At" name="createdAt">
                <RangePicker />
            </Form.Item> */}
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};
