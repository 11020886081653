import {
    IResourceComponentsProps,
    useMany,
    CrudFilters,
    HttpError,
} from "@pankod/refine-core";

import {
    List,
    Table,
    TextField,
    useTable,
    Space,
    EditButton,
    ShowButton,
    useSelect,
    Form,
    Input,
    Button,
    DatePicker,
    DateField,
    Card,
    Icons,
    Select,
    Tag,
    FormProps,
    Row,
    Col,
    UrlField 
} from "@pankod/refine-antd";

import { IPost, IProvince, IDkkdFilterVariables, IDkkdSearch } from "interfaces";

const { RangePicker } = DatePicker;

export const BusinessList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, searchFormProps } = useTable<
    IDkkdSearch,        
        HttpError,
        IDkkdFilterVariables
    >({
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, province, key } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "provinceId",
                    operator: "eq",
                    value: province,
                },
                {
                    field: "key",
                    operator: "eq",
                    value: key,
                },
                // {
                //     field: "createdAt",
                //     operator: "gte",
                //     value: createdAt ? createdAt[0].toISOString() : undefined,
                // },
                // {
                //     field: "createdAt",
                //     operator: "lte",
                //     value: createdAt ? createdAt[1].toISOString() : undefined,
                // },
            );

            return filters;
        },
    });

    // const provinceIds = tableProps?.dataSource?.map((item) => item.ProvinceId) ?? [];
    //   const { data, isLoading } = useMany<IProvince>({
    //       resource: "provinces",
    //       ids: provinceIds,
    //       queryOptions: {
    //           enabled: provinceIds.length > 0,
    //       },
    //   });

      
    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List>
                    <Table {...tableProps} rowKey="Id">
                        <Table.Column dataIndex="id" title="ID" />
                        <Table.Column<IDkkdSearch> dataIndex="COMPANY_NAME_VN" title="Business Name" render={(id: string, record) => <UrlField href={"/dkkds/show/"+record.id} value={id} />} />
                        <Table.Column dataIndex="TAX_CODE" title="Tax Code" />                        
                        <Table.Column dataIndex="REGISTERED_ADDRESS_VN" title="Registered Address" />
                        <Table.Column<IDkkdSearch>
                            dataIndex="shareholders"
                            title="Shareholders"
                            render={(value, record) => {                                
                                return <div> {record.dscd2024s &&
                                    record.dscd2024s.map((item, index) => (
                                            <p>{item.FOUNDER_NAME} ({item.RATIO_IN_CHARTER_CPT}%)</p>
                                    ))}</div>;
                            }}
                        /> 
                        
                        <Table.Column<IDkkdSearch>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    {/* <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={record.Id}
                                    /> */}
                                    <ShowButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

   const { selectProps: provinceSelectProps } = useSelect<IProvince>({
        resource: "provinces",
        optionLabel: "ProvinceName",
        optionValue: "Id",
   });

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label="Search" name="q">
                <Input
                    placeholder="ID, Title, Content, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Shareholder" name="key">
                <Input
                    placeholder="ID, name, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            {/* <Form.Item label="Status" name="status">
                <Select
                    allowClear
                    options={[
                        {
                            label: "Published",
                            value: "published",
                        },
                        {
                            label: "Draft",
                            value: "draft",
                        },
                        {
                            label: "Rejected",
                            value: "rejected",
                        },
                    ]}
                    placeholder="Post Status"
                />
            </Form.Item> */}
            <Form.Item label="Province" name="province">
                <Select
                    {...provinceSelectProps}
                    allowClear
                    placeholder="Search Provinces"
                />
            </Form.Item>
            {/* <Form.Item label="Created At" name="createdAt">
                <RangePicker />
            </Form.Item> */}
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};
