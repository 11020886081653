import {
    IResourceComponentsProps,
    useMany,
    CrudFilters,
    HttpError,
} from "@pankod/refine-core";

import {
    List,
    Table,
    TextField,
    useTable,
    Space,
    EditButton,
    ShowButton,
    useSelect,
    Form,
    Input,
    Button,
    DatePicker,
    DateField,
    Card,
    Icons,
    Select,
    Tag,
    FormProps,
    Row,
    Col,
} from "@pankod/refine-antd";

import { ILitigationFilterVariables, ILitigation } from "interfaces/litigation";

const { RangePicker } = DatePicker;

export const Litigations: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, searchFormProps } = useTable<
    ILitigation,        
        HttpError,
        ILitigationFilterVariables
    >({
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, key } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                // {
                //     field: "provinceId",
                //     operator: "eq",
                //     value: province,
                // },
                {
                    field: "key",
                    operator: "eq",
                    value: key,
                },
                // {
                //     field: "createdAt",
                //     operator: "gte",
                //     value: createdAt ? createdAt[0].toISOString() : undefined,
                // },
                // {
                //     field: "createdAt",
                //     operator: "lte",
                //     value: createdAt ? createdAt[1].toISOString() : undefined,
                // },
            );

            return filters;
        },
    });

    // const provinceIds = tableProps?.dataSource?.map((item) => item.ProvinceId) ?? [];
    //   const { data, isLoading } = useMany<IProvince>({
    //       resource: "provinces",
    //       ids: provinceIds,
    //       queryOptions: {
    //           enabled: provinceIds.length > 0,
    //       },
    //   });

      
    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List>
                    <Table {...tableProps} rowKey="Id">
                        {/* <Table.Column dataIndex="Id" title="ID" /> */}
                        <Table.Column dataIndex="TenVuAn" title="Tên vụ án" />
                        <Table.Column dataIndex="CapXetXu" title="Cấp xét xử" />
                        <Table.Column dataIndex="ToaXetXu" title="Tòa xét xử" />
                        <Table.Column dataIndex="NgayXetXu" title="Ngày xét xử" />                        
                         
                        {/* <Table.Column
                            dataIndex="createdAt"
                            title="Created At"
                            render={(value) => (
                                <DateField format="LLL" value={value} />
                            )}
                        />
                        <Table.Column
                            dataIndex={["category", "id"]}
                            key="category.id"
                            title="Category"
                            render={(value) => {
                                if (isLoading) {
                                    return <TextField value="Loading..." />;
                                }

                                return (
                                    <TextField
                                        value={
                                            data?.data.find(
                                                (item) => item.id === value,
                                            )?.title
                                        }
                                    />
                                );
                            }}
                        /> */}
                        <Table.Column<ILitigation>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    {/* <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={record.Id}
                                    /> */}
                                    <ShowButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

//    const { selectProps: provinceSelectProps } = useSelect<IProvince>({
//         resource: "provinces",
//         optionLabel: "ProvinceName",
//         optionValue: "Id",
//    });

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label="Tên vụ án" name="q">
                <Input
                    placeholder="Taxcode, name, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Tòa xét xử" name="key">
                <Input
                    placeholder="name, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            {/* <Form.Item label="Status" name="status">
                <Select
                    allowClear
                    options={[
                        {
                            label: "Published",
                            value: "published",
                        },
                        {
                            label: "Draft",
                            value: "draft",
                        },
                        {
                            label: "Rejected",
                            value: "rejected",
                        },
                    ]}
                    placeholder="Post Status"
                />
            </Form.Item> */}
            {/* <Form.Item label="Province" name="province">
                <Select
                    {...provinceSelectProps}
                    allowClear
                    placeholder="Search Provinces"
                />
            </Form.Item> */}
            {/* <Form.Item label="Created At" name="createdAt">
                <RangePicker />
            </Form.Item> */}
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};
