import { useShow, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import { Show, Typography, MarkdownField, Row, Col, Card } from "@pankod/refine-antd";

import { IFullBusiness, IProvince } from "interfaces";


const { Title, Text } = Typography;
import "./styles.css";

export const PostShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IFullBusiness>({resource: "businesses"});
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const shareholders = data?.data.shareholders;
    const directors = data?.data.boardOfDirectors;
    const activities = data?.data.activities;
    
    const { data: categoryData, isLoading: categoryIsLoading } =
        useOne<IProvince>({
            resource: "provinces",
            id: record?.ProvinceId || "",
            queryOptions: {
                enabled: !!record,
            },
        });
        function currencyFormat(num?:Number) {
            return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
         }
         const formatNumber = (num?:number) => {
            return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
           } 
    return (
        <Show isLoading={isLoading} title={record?.BusinessName}>
            
             <Card title="Thông tin pháp lý">
            <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>
                <tbody className="ant-table-tbody">
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell" style={{width: "20%"}}>TaxCode:</td>
                <td className="ant-table-cell" style={{width: "80%"}}>{record?.TaxCode}</td>
            </tr>      
            <tr>
                <td><b>Business Name:</b></td>
                <td><b>{record?.BusinessName}</b></td>
            </tr>     
                <tr>
                    <td>English Name:</td>
                    <td>{record?.EnglishName}</td>
                </tr>
                <tr>
                    <td>Short Name:</td>
                    <td>{record?.ShortName}</td>
                </tr>
                <tr>
                    <td>Registered Address:</td>
                    <td>{record?.RegisteredAddress}</td>
                </tr>
                <tr>
                    <td>City:</td>
                    <td>{record?.City}</td>
                </tr>
                <tr>
                    <td>Amendment Date:</td>
                    <td>{record?.AmendmentDate}</td>
                </tr>
                <tr>
                    <td>Corporation Date:</td>
                    <td>{record?.CorporationDate}</td>
                </tr>
                <tr>
                    <td>Status:</td>
                    <td>{record?.Status}</td>
                </tr>
                <tr>
                    <td>Phone:</td>
                    <td>{record?.Phone}</td>
                </tr>
                <tr>
                    <td>Email:</td>
                    <td>{record?.Email}</td>
                </tr>
                <tr>
                    <td>Website:</td>
                    <td>{record?.Website}</td>
                </tr>
                <tr>
                    <td>Charter Capital:</td>
                    <td>{formatNumber(record?.CharterCapital)}</td>
                </tr>
                </tbody>
            </table>
            </Card>
            {/* <MarkdownField value={record?.TaxCode} /> */}
           
            {/* <Title level={5}>Category</Title>
            <Text>
                {categoryIsLoading ? "Loading..." : categoryData?.data.title}
            </Text> */}
            <Card title="Người đại diện pháp luật">
            {directors &&
              directors.map((item, index) => (
                <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>  
                <tbody className="ant-table-tbody">
                    <tr>
                        <td className="ant-table-cell" style={{width: "20%"}}><b>Name:</b></td>
                        { item.ProfileId?
                        <td className="ant-table-cell" style={{width: "80%"}}><b><a href={"/persons/show/"+item.ProfileId}>{item.REPRESENTATIVE_NAME}</a></b></td>
                        : <td className="ant-table-cell" style={{width: "80%"}}><b>{item.REPRESENTATIVE_NAME}</b></td>
                        }
                    </tr>
                    <tr>                        
                    <td>Date of birth:</td>
                    <td>{item.DATE_OF_BIRTH}</td>
                    </tr>
                    <tr>                        
                    <td>ID Number:</td>
                    <td>{item.PERS_DOC_NO}</td>
                    </tr>
                    <tr>                        
                    <td>ID Issue Date:</td>
                    <td>{item.PERS_DOC_ISSUE_DATE}</td>
                    </tr>
                    <tr>
                    <td>Address:</td>
                    <td>{item.ADDRESS_VN}</td>
                </tr>
                <tr>
                    <td>City:</td>
                    <td>{item.CITY}</td>
                </tr>
                <tr>
                    <td>Phone:</td>
                    <td>{item.PHONE}</td>
                </tr>
                <tr>
                    <td>Nationality:</td>
                    <td>{item.NATIONALITY}</td>
                </tr>
                <tr>
                    <td>Position:</td>
                    <td>{item.POSITION}</td>
                </tr>               
                </tbody>
                </table>
              ))} 
            </Card>
            <Card title="Danh sách công ty con - Subsidiaries">
               
                <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>  
                <tbody className="ant-table-tbody">
                    <tr>
                        <td className="ant-table-cell" style={{width: "20%"}}><b></b></td>
                        
                        <td className="ant-table-cell" style={{width: "80%"}}><b><a target="_blank" href={"/shareholders?current=1&pageSize=10&filters[0][field]=key&filters[0][operator]=eq&filters[0][value]="+record?.TaxCode}>Xem các công ty con</a></b></td>
                        
                    </tr>
                    
                </tbody>
                </table>                                 
                
            </Card>
            <Card title="Danh sách cổ đông">
                
                              
                {shareholders &&
              shareholders.map((item, index) => (
                <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>  
                <tbody className="ant-table-tbody">
                    <tr>
                        <td className="ant-table-cell" style={{width: "20%"}}><b>Shareholder Name:</b></td>
                        {/* <td className="ant-table-cell" style={{width: "80%"}}><b>{item.FOUNDER_NAME}</b></td> */}
                        { item.ProfileId?
                        <td className="ant-table-cell" style={{width: "80%"}}><b><a href={"/persons/show/"+item.ProfileId}>{item.FOUNDER_NAME}</a></b></td>
                        : <td className="ant-table-cell" style={{width: "80%"}}><b>{item.FOUNDER_NAME}</b></td>
                        }
                    </tr>
                    <tr>                        
                    <td>Date of birth:</td>
                    <td>{item.DATE_OF_BIRTH}</td>
                    </tr>
                    <tr>                        
                    <td>ID Number:</td>
                    <td>{item.PERS_DOC_NO}</td>
                    </tr>
                    <tr>                        
                    <td>ID Issue Date:</td>
                    <td>{item.PERS_DOC_ISSUE_DATE}</td>
                    </tr>
                    <tr>
                    <td>Address:</td>
                    <td>{item.ADDRESS_VN}</td>
                </tr>
                <tr>
                    <td>City:</td>
                    <td>{item.CITY}</td>
                </tr>
                <tr>
                    <td>Phone:</td>
                    <td>{item.PHONE}</td>
                </tr>
                <tr>
                    <td>Nationality:</td>
                    <td>{item.NATIONALITY}</td>
                </tr>
                <tr>
                    <td>Ratio in Charter Capital:</td>
                    <td>{item.RATIO_IN_CHARTER_CPT}%</td>
                </tr>
                <tr>
                    <td>Total shares value:</td>
                    <td>{formatNumber(item.TOTAL_SHARES_VALUE)}</td>
                </tr>
                </tbody>
                </table>
              ))}                   
                
            </Card>
            <Card title="Ngành nghề">                
                              
                {activities &&
              activities.map((item, index) => (
                <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>  
                <tbody className="ant-table-tbody">
                    <tr>
                        <td className="ant-table-cell" style={{width: "20%"}}>Main:</td>
                        <td className="ant-table-cell" style={{width: "80%"}}>{item.MainActivityCode} - {item.MainActivity}</td>
                    </tr>
                    <tr>                        
                    <td>Other Activity Codes:</td>
                    <td>{item.OtherActivityCodes}</td>
                    </tr>
                    <tr>                        
                    <td>Other Activities:</td>
                    <td>{item.OtherActivities}</td>
                    </tr>
                    <tr>                        
                    <td>Auxiliary Activities:</td>
                    <td>{item.AuxiliaryActivities}</td>
                    </tr>
                   
                </tbody>
                </table>
              ))}                   
                
            </Card>
        </Show>
    );
};
