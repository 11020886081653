import { useShow,useMany, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import { Show, Typography, Card, useTable } from "@pankod/refine-antd";

import { IFullPerson } from "interfaces/person";

const { Title, Text } = Typography;

export const PersonShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IFullPerson>({resource: "persons"});
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const shareholders = data?.data.shareholders;
    const directors = data?.data.boardOfDirectors;
    // const { data: categoryData, isLoading: categoryIsLoading } =
    // useMany<IFullShareholder>({
    //          resource: "shareholders",
    //          personId: record?.PersonId,
    //          queryOptions: {
    //              enabled: !!record,
    //          },
    //      });
    const formatNumber = (num?:number) => {
        return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       }
    return (
        <Show isLoading={isLoading} title={record?.PersonName}>
            <Card title="Thông tin cá nhân">
            <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>
                <tbody className="ant-table-tbody">
                <tr>
                <td><b>Name:</b></td>
                <td><b>{record?.PersonName}</b></td>
            </tr>            
            <tr>
                    <td>Date of birth/Issue:</td>
                    <td>{record?.DateOfBirth}</td>
                </tr> 
                <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell" style={{width: "20%"}}>ID No.:</td>
                <td className="ant-table-cell" style={{width: "80%"}}>{record?.IdNumber}</td>
            </tr>
                <tr>
                    <td>Issue Date:</td>
                    <td>{record?.IdIssueDate}</td>
                </tr>
                <tr>
                    <td>Address:</td>
                    <td>{record?.AddressVN}</td>
                </tr>
                <tr>
                    <td>City:</td>
                    <td>{record?.City}</td>
                </tr>
                <tr>
                    <td>Natinality:</td>
                    <td>{record?.Nationality}</td>
                </tr>                
                <tr>
                    <td>Phone:</td>
                    <td>{record?.PHONE}</td>
                </tr>
                {/* <tr>
                    <td><b>Shared at:</b></td>
                    <td><b>{record?.business.BusinessName} - {record?.business.TaxCode}</b></td>
                </tr>
                <tr>
                    <td>Ratio:</td>
                    <td>{record?.RATIO_IN_CHARTER_CPT}%</td>
                </tr> */}
                {/* <tr>
                    <td>Total shares:</td>
                    <td>{formatNumber(record?.TOTAL_SHARES_VALUE)}</td>
                </tr>                 */}
                </tbody>
            </table>
            </Card>
            <Card title="Đại diện pháp luật">
            {directors &&
              directors.map((item, index) => (
                <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>  
                <tbody className="ant-table-tbody">                    
                <tr>
                    <td className="ant-table-cell" style={{width: "20%"}}><b>At:</b></td>
                    <td className="ant-table-cell" style={{width: "80%"}}><b><a href={"/businesses/show/"+item.BusinessId}>{item.COMPANY_NAME_VN}</a></b></td>
                </tr>
                <tr>
                    <td>Tax code:</td>
                    <td>{item.TAX_CODE}</td>
                </tr>
                <tr>
                    <td>Position:</td>
                    <td>{item.POSITION}</td>
                </tr>               
                </tbody>
                </table>
              ))} 
            </Card>
            <Card title="Cổ đông">                
                              
                {shareholders &&
              shareholders.map((item, index) => (
                <table style={{width: "auto", minWidth: "100%", tableLayout: "auto"}}>  
                { item.business &&
                <tbody className="ant-table-tbody">                    
                    <tr>
                        <td className="ant-table-cell" style={{width: "20%"}}><b>Shareholder at:</b></td>
                        <td className="ant-table-cell" style={{width: "80%"}}><b><a href={"/businesses/show/"+item.business.id}>{item.business.BusinessName}</a></b></td>
                    </tr>
                    <tr>
                    <td>Taxcode:</td>
                    <td>{item.business.TaxCode}</td>
                    </tr>
                    <tr>
                    <td>Address:</td>
                    <td>{item.business.RegisteredAddress}</td>
                    </tr>                   
                    
                <tr>
                    <td>Ratio in Charter Capital:</td>
                    <td>{item.RATIO_IN_CHARTER_CPT}%</td>
                </tr>
                <tr>
                    <td>Total shares value:</td>
                    <td>{formatNumber(item.TOTAL_SHARES_VALUE)}</td>
                </tr>
                </tbody>
                }
                </table>
              ))}                   
                
            </Card>
        </Show>
    );
};
