import { AuthProvider } from "@pankod/refine-core";

//import { supabaseClient } from "utility";

const authProvider: AuthProvider = {
    login: async ({ email, password }) => {
        // const { user, error } = await supabaseClient.auth.signIn({
        //     email,
        //     password,
        // });

        // if (error) {
        //     return Promise.reject(error);
        // }

        // if (user) {
        //     return Promise.resolve();
        // }
        if (email === "info@vietnamcredit.com.vn") {
            localStorage.setItem("username", email);
            return Promise.resolve();
          }
    
          return Promise.reject();
    },
    logout: async () => {
        // const { error } = await supabaseClient.auth.signOut();

        // if (error) {
        //     return Promise.reject(error);
        // }

        // return Promise.resolve("/");

        localStorage.removeItem("username");
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        // const session = supabaseClient.auth.session();

        // if (session) {
        //     return Promise.resolve();
        // }

        // return Promise.reject();

        if(localStorage.getItem("username") )
            return Promise.resolve();
        return Promise.reject();
        //? return Promise.resolve() : return Promise.reject()
    },
    getPermissions: async () => {
        // const user = supabaseClient.auth.user();

        // if (user) {
        //     return Promise.resolve(user.role);
        // }
        return Promise.resolve(["vietnamcredit@123"])
    },
    getUserIdentity: async () => {
        // const user = supabaseClient.auth.user();

        // if (user) {
        //     return Promise.resolve({
        //         ...user,
        //         name: user.email,
        //     });
        // }

        return Promise.resolve({
            id: 1,
            name: "Vietnam Credit",
            avatar:
              "https://unsplash.com/photos/IWLOvomUmWU/download?force=true&w=640"
          })
    },
};

export default authProvider;
